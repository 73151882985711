/* eslint-disable indent */
import { PageElement, html, css } from 'Elements';
import { Router } from 'Router';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

import App from 'App';
import TicketsDataManager from './TicketsDataManager.js';
import './TicketsTable.js';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`

        :host {
          --sl-color-blue-light: hsl(210, 100%, 70%);
          --sl-color-blue-dark: hsl(210, 100%, 42%);
          --sl-color-green-light: hsl(120, 61%, 70%);
          --sl-color-green-dark: hsl(120, 61%, 42%);
          --sl-color-orange-light: hsl(24.6, 95%, 70%);
          --sl-color-orange-dark: hsl(24.6, 95%, 42%);
          --sl-color-red-light: hsl(0, 84.2%, 70%);
          --sl-color-red-dark: hsl(0, 84.2%, 42%);
          --sl-color-gray-light: hsl(240, 5.2%, 70%);
          --sl-color-gray-dark: hsl(240, 5.2%, 42%);
        }

        .grid_status {
          display:flex;
          gap:10px;
          justify-content:space-around;
          padding:5px;
          margin-top:4px;
          user-select:none;
        }

        .grid_status .status {
          display:flex;
          gap:5px;
          width:100%;
          flex-direction:column;
          align-items:center;
          color:white;
          padding:20px;
          position:relative;
          min-height:50px;
          max-height:50px;
          cursor:pointer;
        }

        .grid_status .status.selected {
          box-shadow: 0px 0px 0px 3px var(--sl-color-primary-600);
        }

        .grid_status .status .title {
          background-color:#00000055;
          position:absolute;
          top:0px;
          left:0px;
          right:0px;
          height:20px;
          padding:5px;
        }

        .grid_status .status .count {
          font-size:3em;
          margin-top:8px;
          text-shadow: 5px 5px 3px #00000022;
        }

        .grid_status .status .graph {
          position:absolute;
          margin:1px;
          left:0px;
          right:0px;
          bottom:0px;
        }

        .grid_status .status.open {
          /*background: linear-gradient(135deg, var(--sl-color-orange-light), var(--sl-color-orange-dark));*/
          background: linear-gradient(135deg, var(--sl-color-orange-light), var(--sl-color-orange-dark));
        }

        .grid_status .status.resolved {
          background: linear-gradient(135deg, var(--sl-color-green-light), var(--sl-color-green-dark));
        }

        .grid_status .status.new {
          background: linear-gradient(135deg, var(--sl-color-red-light), var(--sl-color-red-dark));
        }

        .grid_status .status.false-positive {
          background: linear-gradient(135deg, var(--sl-color-gray-light), var(--sl-color-gray-dark));
        }

        .grid_status .status.rejected {
          /* @TODO => gray */
          background: linear-gradient(135deg, var(--sl-color-blue-light), var(--sl-color-blue-dark));
        }

        .severities {
          display:flex;
          gap:10px;
          padding:5px;
          justify-content:space-evenly;
          align-items:center;
          user-select:none;
        }

        .severities .severity {
          white-space: nowrap;
          text-align: center;
          font-size: 1.1em;
          flex: 1;
          color: white;
          align-items: center;
          line-height: 1.5em;
          gap: 10px;
          justify-content: center;
          padding: 3px;
          padding-left: 10px;
          padding-right: 10px;
          display: flex;
          text-shadow: 1px 1px 1px #00000077;
          cursor:pointer;
        }
        
        .severity.selected {
          box-shadow: 0px 0px 0px 3px var(--sl-color-primary-600);
        }

        .severities .label {
          opacity:1;
        }

        .severities .count {
          font-size:1.5em;
        }

        .filter {
          display:grid;
          grid-template-columns:250px 1fr;
          width:100%;
          gap:10px;
        }

        .charts {
          display:flex;
          gap:20px;
        }

        .charts > * {
          width:100%;
          min-height:50vh;
          flex:1;
        }

        .charts_classification {
          display:flex;
          gap:20px;
        }

        .charts_classification > * {
          width:100%;
          min-height:50vh;
          flex:1;
        }

        .chart_buttons {
          display:flex;
          gap:10px;
          padding-right:10px;
        }

        .chart_buttons sl-button::part(label) {
          display: flex;
          line-height: initial;
          align-items: center; 
        }

        .chart_buttons m-icon {
          font-size:1.5em;
        }

        sl-switch {
          margin-top:5px;
        }

        sl-switch::part(label) {
          text-transform: initial;
          font-size:0.9em;
        }
        
        @media (max-width: 1200px) {
          .charts {
            flex-direction:column;
          }
        }
      `
    ];
  }

  static get properties() {
    return {
      loading: { type: Boolean }
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english: {
          translation: {
            title:'Alerts & Incidents',
            graphDistributionStatus: 'Alerts & Incidents Status',
            graphDistributionClassification:'Incidents Classification',
            graphDistributionSubClassification:'Incidents Subclassification',
            until:'until',
            from:'from',
            to:'to',
            ticket_status:{
              'new': 'New',
              'open': 'In Progress',
              'resolved': 'Resolved',
              //'rejected': 'Rejected',
              'false-positive': 'False Positive'
            }
          },
        },
        french: {
          translation: {
            title:'Alertes & Incidents',
            graphDistributionStatus: 'Statuts des alertes & incidents',
            graphDistributionClassification: 'Classification des incidents',
            graphDistributionSubClassification:'Sous Classification des incidents',
            until:'jusqu\'au',
            from:'du',
            to:'au',
            ticket_status:{
              'new': 'Alertes',
              'open': 'Incidents en cours',
              'resolved': 'Alertes & Incidents clôturés',
              //'rejected': 'Rejetés',
              'false-positive': 'Faux Positif'
            }
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.layout = 'large';
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.dataManagerInstance = new TicketsDataManager({ parent:this });
    this.loading = true;
    this.firstTime = true;

    this.customers = [
      { id: 'hubone', name: 'HubOne', queues:['11', '12', '13', '14'] },
      { id: 'sysdream', name: 'SysDream', queues:['7', '8', '9', '10'] },
      { id: 'adp', name: 'Aéroports de Paris', queues:['15', '16', '17', '19'] },
      { id: 'louviere', name: 'La Louvière' },
      { id: 'oxand', name: 'Oxand', },
      { id: 'plb', name: 'PLB Consulting', },
      { id: 'haudecoeur', name: 'HaudeCoeur', },
      { id: 'tutelaire', name: 'Tutélaire', },
      { id: 'ozitem', name: 'Ozitem', },
      { id: 'csirt-aviation-france', name:'CSIRT Aviation France', queues:['19', '20', '21', '22'] }
    ]

    this.dataManagerInstance.reset();
  }

  connectedCallback() {
    super.connectedCallback();
    this.setDayJsLocale();
    window.addEventListener('language-changed', this.onLanguageChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-changed', this.onLanguageChanged);
  }

  setDayJsLocale() {
    dayjs.locale(App.config.lg);
  }

  async onLanguageChanged() {
    this.setDayJsLocale();
    await this.dataManagerInstance.prepareData();
    this.requestUpdate();
  }

  async refresh() {
    this.dateSelector = this.dateSelector || this.shadowRoot.getElementById('dateSelector');
    this.customerSelector = this.customerSelector || this.shadowRoot.getElementById('customerSelector');

    this.loading = true;
    this.loader = this.loader || this.shadowRoot.querySelector('sl-progress-bar');
    this.loader.style.visibility = 'visible';

    await this.dataManagerInstance.refresh({
      customers: this.customerSelector.value,
      period: this.dateSelector.value,
    });

    this.loading = false;
    this.loader.style.visibility = 'hidden';
    this.requestUpdate();
    this.firstTime = false;
  }

  async firstUpdated() {
    super.firstUpdated();
    await this.refresh();
  }

  formatPeriod() {
    if (!this.dateSelector) {
      return '';
    }

    if (!this.dateSelector.value) {
      return `${this._tl('until')} ${this.dataManagerInstance.formatDate()}`;
    }

    const range = this.dateSelector.getDatesRange(this.dateSelector.value);
    return `${this._tl('from')} ${this.dataManagerInstance.formatDate(range.start)} ${this._tl('to')} ${this.dataManagerInstance.formatDate(range.end)}`;
  }

  onChartTypeUpdate(ev) {
    const target = ev.target.closest('sl-radio-group');
    const gtype = target.value;
    if (!gtype) return;

    const chart = this.qs('apex-chart');

    if (gtype === 'bar') {
      chart.type = 'bar';
      chart.fillType = 'gradient';
      chart.strokeWidth = 0;
      chart.gradientType = 'horizontal';      
      return;
    }

    if (gtype === 'line') {
      chart.type = 'line';
      chart.fillType = 'solid';
      chart.strokeWidth = 2;
      return;
    }

    if (gtype === 'area') {
      chart.type = 'area';
      chart.gradientType = 'vertical';
      chart.strokeWidth = 2;
      chart.fillType = 'gradient';
      
      return;
    }

    chart.stacked = this.qs('sl-switch').checked;
  }

  onChartStackedUpdate(ev) {
    const chart = this.qs('apex-chart');
    chart.stacked = ev.target.checked;
  }

  onChartDataLabelUpdate(ev) {
    const chart = this.qs('apex-chart');
    chart.dataLabelsEnabled = ev.target.checked;
  }

  resetStatusSelection(ev, target) {
    if (ev.ctrlKey) {
      
      if (target.classList.contains('status')) {
        const selected = this.qs('.status.selected');
        if (selected) selected.classList.remove('selected');
      }

      if (target.classList.contains('severity')) {
        const selectedSeverity = this.qs('.severity.selected');
        if (selectedSeverity) selectedSeverity.classList.remove('selected');
      }
      
    } else {
      
      const selected = this.qs('.status.selected');
      if (selected) selected.classList.remove('selected');

      const selectedSeverity = this.qs('.severity.selected');
      if (selectedSeverity) selectedSeverity.classList.remove('selected');

    }
  }

  RouterTickets() {
    // take selected status and selected severity, and filter the tickets
    const tabTickets = this.qs('tickets-table');
    const status = this.qs('.status.selected')?.getAttribute('status');
    const severity = this.qs('.severity.selected')?.getAttribute('severity');
    let url = '/private/soc/alert/dashboard?t=tickets';
    if (status) url+=`&status=${status}`;
    if (severity) url+=`&severity=${severity}`;
    Router.go(url);
    tabTickets.filter({ status, severity });
  }

  onStatusClick(ev) {
    const target = ev.target.closest('.status');
    if (!target) return;
    this.resetStatusSelection(ev, target);
    target.classList.add('selected');
    this.RouterTickets();
  }

  onSeverityClick(ev) {
    const target = ev.target.closest('.severity');
    if (!target) return;
    this.resetStatusSelection(ev, target);
    target.classList.add('selected');
    this.RouterTickets();
  }

  renderStatusGrid() {
    return html`
      <div>
        <div class="grid_status">
          ${Object.keys(this.dataManagerInstance.statsByStatus).map(status => html`
            <div class="status ${status}" status="${status}" @click=${this.onStatusClick}>
              <div class="title">${this._tl(`ticket_status.${status}`)}</div>
              <div class="count">${this.dataManagerInstance.statsByStatus[status]}</div>
            </div>
          `)}
        </div>
        <div class="severities">
          ${Object.entries(this.dataManagerInstance.severityLevels).map(([key, severity]) => {
            let border = '';
            if (key === 'S0') border='box-shadow:inset 0px 0px 4px -1px red;';
            return html`
              <div class="severity" style="background-color:${severity.color};${border}" severity="${key}" @click=${this.onSeverityClick}>
              <span class="label">${severity.label}</span><span class="count">${this.dataManagerInstance.graphDataSeverity[key] || 0}</span>
              </div>`;
          })}
        </div>
      <div>
    `;
  }

  renderDistribution() {

    /*
    console.log('distribution data', this.dataManagerInstance.graphDataDistribution.data);
    console.log('distribution label', this.dataManagerInstance.graphDataDistribution.labels);
    console.log('distribution colors', this.dataManagerInstance.graphDataDistribution.colors);

    console.log('time data', this.dataManagerInstance.graphDataBlocksTime.data);
    console.log('time label', this.dataManagerInstance.graphDataBlocksTime.labels);
    console.log('time colors', this.dataManagerInstance.graphDataBlocksTime.colors);

    console.log('classification data', this.dataManagerInstance.graphDataClassification.data);
    console.log('classification label', this.dataManagerInstance.graphDataClassification.labels);

    console.log('subclassification data', this.dataManagerInstance.graphDataSubClassification.data);
    console.log('subclassification label', this.dataManagerInstance.graphDataSubClassification.labels);

    console.log('severity data', this.dataManagerInstance.graphDataSeverity.data);
    console.log('severity label', this.dataManagerInstance.graphDataSeverity.labels);
    */

    return html`
      <div>
        <section-header size="medium">
          ${this._tl('graphDistributionStatus')}
          <div slot="right">
            <div class="chart_buttons">
              <sl-switch size="small" checked @sl-change=${this.onChartStackedUpdate}>Cumul</sl-switch>
              <sl-switch size="small" @sl-change=${this.onChartDataLabelUpdate}>Chiffres</sl-switch>
              <sl-radio-group size="small" name="type" value="bar" @sl-change=${this.onChartTypeUpdate}>
                <sl-radio-button checked value="bar"><m-icon name="bar_chart"></m-icon></sl-radio-button>
                <sl-radio-button value="line"><m-icon name="stacked_line_chart"></m-icon></sl-radio-button>
                <sl-radio-button value="area"><m-icon name="area_chart"></m-icon></sl-radio-button>
              </sl-radio-group>           
            </div>
          </div>
        </section-header>
        <div style="height:15px"></div>

        <apex-chart type="bar" style="height:50vh;" id="tickets-distribution"
          strokeWidth=0
          gradientType="horizontal"
          stacked
          animationsEnabled
          legendPosition="top"
          .series=${this.dataManagerInstance.graphDataBlocksTime.data}
          .labels=${this.dataManagerInstance.graphDataBlocksTime.labels}
          .colors=${this.dataManagerInstance.graphDataBlocksTime.colors}
        ></apex-chart>
      </div>
    `
  }

  renderClassification() {
    
    const style='height:45vh;';
    const strokeWidth = 1;
    const legendPosition = 'bottom';
    const legendFontSize = '13px';
    const pieCustomScale = 1;
    return html`
      <div class="charts_classification">
        <div>
          <section-header size="medium">${this._tl('graphDistributionClassification')}</section-header>
          <apex-chart type="donut" style="${style}" id="tickets-classification"
            strokeWidth=${strokeWidth}
            legendFontSize="${legendFontSize}"
            legendPosition="${legendPosition}"
            pieCustomScale=${pieCustomScale}
            .series=${this.dataManagerInstance.graphDataClassification.data}
            .labels=${this.dataManagerInstance.graphDataClassification.labels}
          ></apex-chart>
        </div>
        <div>
          <section-header size="medium">${this._tl('graphDistributionSubClassification')}</section-header>
          <apex-chart type="donut" style="${style}" id="tickets-subclassification"
            strokeWidth=${strokeWidth}
            legendFontSize="${legendFontSize}"
            legendPosition="${legendPosition}"
            pieCustomScale=${pieCustomScale}
            .series=${this.dataManagerInstance.graphDataSubClassification.data}
            .labels=${this.dataManagerInstance.graphDataSubClassification.labels}
          ></apex-chart>
        </div>
      </div>
    `
  }


  renderLoading() {
    this._log.debug('renderLoading');
    return html`<sl-progress-bar style="--height: 2px;" indeterminate></sl-progress-bar>`;
  }

  render() {
    return html`
      <section-header micon="bar_chart_4_bars" size="big" backroute="../../">${this._tl('title')} - ${this.formatPeriod()}</section-header>
      ${this.renderLoading()}
      <br/>
      <div class="filter">
        <select-date-range id="dateSelector" defaultValue="1y" @sl-change="${this.refresh}"></select-date-range>
        <select-customers id="customerSelector" .customers="${this.customers}" @change="${this.refresh}"></select-customers>
      </div>
      
      ${this.loading && this.firstTime
        ? ''
        : html`
          ${this.renderStatusGrid()}<br/>

          <tab-group size="medium">
            <sl-tab slot="nav" panel="stats">Statistiques</sl-tab>
            <sl-tab slot="nav" panel="tickets">Tickets</sl-tab>

            <sl-tab-panel name="stats">
              <div class="charts">
                ${this.renderDistribution()}
                ${this.renderClassification()}
              </div>
            </sl-tab-panel>

            <sl-tab-panel name="tickets">
              <tickets-table 
                .tickets=${this.dataManagerInstance.ticketsFiltered} 
                .customers=${this.customers} 
                .parent=${this}
              ></tickets-table>
            </sl-tab-panel>

          </tab-group>
          `
        }
      
      <br/><br/><br/>
    `
  }
}

customElements.define('page-alerts-dashboard', Page);