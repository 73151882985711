import { BaseElement, html, css, unsafeHTML, formCss } from 'Elements';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek'; // Pour gérer les semaines
import localizedFormat from 'dayjs/plugin/localizedFormat'; // Pour les formats localisés

// Extensions de dayjs
dayjs.extend(isoWeek);
dayjs.extend(localizedFormat);

class TicketsTable extends BaseElement {
  static get styles() {
    return [
      formCss,
      css`
        :host {
          overflow: auto;
          overflow-x: hidden;
          /*max-height: 80vh;*/
          display:block;
        }

        table {
          width:100%;
          font-size:0.9em;
          padding:0px;
          padding-right:10px;
        }

        th, td {
          padding: 8px;
        }

        th, td {
          border-left: 1px solid var(--table-list-odd-background-color);;
          border-right: 1px solid var(--table-list-odd-background-color);
          padding: 8px;
        }

        tr:nth-child(even) {
          background-color: var(--table-list-odd-background-color);
        }

        tr:nth-child(odd) {
        }

        tr:hover {
          background-color: var(--table-list-hover-background-color);
        }
            
        .status {
          width:190px;
          color:white;
          padding:3px;
          padding-left:5px;
          padding-right:5px;
          border-radius:3px;
          user-select:none;
          margin-right:5px;
        }

        .nowrap {
          white-space:nowrap;
        }

        th {
          text-align: left;
          color:var(--sl-color-neutral-700);
          font-weight:normal;
          background-color: var(--sl-color-neutral-100);
        }

        thead  {
          background-color: var(--sl-color-neutral-100);
          box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 8px -2px;
          top:0px;
          position:sticky;
          z-index:10;
        }

        tr.gdi-test td {
          color: var(--sl-color-neutral-500);
        }

        .toolbar {
          position:absolute;
          top: -1px;
          right:15px;
          margin-bottom:10px;
          margin-right:10px;
          display:flex;
          justify-content:flex-end;
          gap:10px;
        }

        .group-header {
          background-color: var(--table-list-group-background-color);
          font-size: 1em;
          padding:5px;
          padding-right:10px;
          padding-left: 10px;
          color:white;
          text-transform: capitalize;
          display:inline-block;
          border-radius: 20px;
          width:200px;
        }

        td m-icon {
          margin-top:1px;
          margin-right:4px;
          float:left;
          font-size:1.4em;
        }

        .bubble {
          color: white;
          text-shadow: 1px 1px 1px #00000077;
          padding: 3px;
          font-size:1.1em;
          line-height:24px;
          border-radius: 50%;
          min-width: 25px;
          min-height: 25px;
          display: inline-block;
          text-align: center;
        }
      `
    ];
  }

  static get properties() {
    return {
      tickets: { type: Array },
      customers: { type: Array },
      parent: { type: Object },
      groupBy: { type: String },  // Nouvelle propriété pour le regroupement
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.tickets = [];
    this.customers = [];
    this.parent = null;
    this.groupBy = 'day';  // Par défaut, regroupé par jour
    this.changeGrouping = this.changeGrouping.bind(this);
  }

  async updated(changedProperties) {
    if (changedProperties.has('tickets')) {
      this._log.debug('updated', this.tickets.length);
      this.filteredTickets = [...this.tickets];
      await this.requestUpdate();
    }
  }

  filter(opts) {
    if (!opts) return;

    this.filteredTickets = this.tickets;

    if (opts.status) {
      this.filteredTickets = this.filteredTickets.filter(ticket => ticket.Status === opts.status);
    }

    if (opts.severity) {
      this.filteredTickets = this.filteredTickets.filter(ticket => {
        for (const customField of ticket.CustomFields) {
          if (customField.id === '22') {
            for (const value of customField.values) {
              if (value && value.startsWith(opts.severity)) {
                return true;
              }
            }
          }
        }
      });
    }

    this.requestUpdate();

  }

  groupTickets() {
    const grouped = {};

    this.filteredTickets.forEach(ticket => {
      const createdDate = dayjs(ticket.Created);
      let key;

      if (this.groupBy === 'day') {
        key = createdDate.format('dddd DD/MM/YYYY'); // Formate pour regrouper par jour
      } else if (this.groupBy === 'week') {
        const startOfTheWeek = createdDate.isoWeek(); // Numéro de la semaine ISO
        key = `Semaine ${startOfTheWeek}: ${createdDate.startOf('isoWeek').format('DD/MM')} - ${createdDate.endOf('isoWeek').format('DD/MM')}`;
      } else if (this.groupBy === 'month') {
        key = createdDate.format('MMMM YYYY'); // Regroupe par mois
      }

      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(ticket);
    });

    return grouped;
  }


  getCustomerName(ticket) {
    let customerName = '';
    for (const customField of ticket.CustomFields) {
      if (customField.id === '16') {
        //console.log('getCustomerName: custom field', ticket.id, JSON.stringify(customField.values));
        customerName = customField.values[0];
      }
    }

    /*
    if (!customerName) {
      this.customers.forEach(customer => {
        if (customer.queues?.includes(ticket.Queue.id)) {
        console.log('getCustomerName: queue', ticket.id, customer.name);
          customerName = customer.name.toUpperCase();
        }
      });
    }
    */

    return customerName || html`<span style="color:red">Non affecté</span>`;
  }

  getCustomerIcon(/*ticket*/) {
    return 'apartment';
  }

  getSeverityBubble(ticket) {
    let value = 'S0';
    for (const customField of ticket.CustomFields) {
      if (customField.id === '22') {
        for (value of customField.values) {
          if (value) {
            value = value.split(' ')[0].toUpperCase();
          }
        }
      }
    }
    const bgColor =  this.parent.dataManagerInstance.severityLevels[value].color;
    //console.log(bgColor);
    // bgcolor can be hexa or hsl, we need to reduce the opacity
    const rgba = bgColor;//.replace(')', ',0.5)').replace('hsl', 'rgba');
    return html`<span class="bubble" style="background-color:${rgba};">${value}</span>`;
  }

  getTicketStatus(ticket) {
    let status = '';
    if (ticket.Status === 'new') {
      status = 'Alerte';
    }

    if (ticket.Status === 'open') {
      status = 'Incident';
    }

    if (ticket.Status === 'resolved') {
      status = 'Résolu';
    }

    const bgColor =  this.parent.dataManagerInstance.statusColors[ticket.Status];

    let str = `<span class="status" style="background-color:${bgColor}">${status}</span>`;

    if (this.isTicketGDITest(ticket)) {
      str+= '<span class="status" style="background-color:#ff0000">GDI TEST</span>';
    }

    return unsafeHTML(`${str}`);
  }

  getTicketId(ticket) {
    if (ticket.hash) {
      return html`<a target="t${ticket.id}" href="/private/soc/alert/${ticket.hash}/edit">${ticket.id}</a>`;
    } 
    
    return ticket.id.toString();
  }


  isTicketGDITest(ticket) {
    if (ticket.CustomFields) {
      for (const customField of ticket.CustomFields) {
        if (customField.id === '39') {
          if (customField.values.includes('GDI TESTS')) {
            return true;
          }
        }
      }
    }
    return false;
  }

  renderToolbar() {
    return html`
      <div class="toolbar">
        <sl-select size="small" value="day" placeholder="Grouper par ..." style="width:150px" @sl-change=${this.changeGrouping}>
          <sl-option value="day">Jour</sl-option>
          <sl-option value="week">Semaine</sl-option>
          <sl-option value="month">Mois</sl-option>
        </sl-select>
      </div>
    `;
  }

  changeGrouping(event) {
    this._log.debug('changeGrouping', event.target.value);
    this.groupBy = event.target.value;
    this.requestUpdate();
  }

  renderTickets() {
    if (!this.filteredTickets) {
      console.warn('no ticket to display');
      return;
    }

    const groupedTickets = this.groupTickets();
    const displayCustomerColumn = this.parent.customerSelector.value?.length !== 1;

    return html`
      <table cellspacing="0" cellpadding="0">
        <thead>
          <tr>
            <th width="30">Sev.</th>
            <th width="70">#</th>
            ${displayCustomerColumn ? html`<th width="200">Client</th>` : ''}
            <th>Sujet</th>
            <th width="200">Status</th>
          </tr>
        </thead>
        <tbody>
          ${Object.keys(groupedTickets).map(group => html`
            <tr><td colspan="70"><div class="group-header">${group}</div></td></tr>
            ${groupedTickets[group].map(ticket => html`
              <tr class="${this.isTicketGDITest(ticket) ? 'gdi-test' : ''}">
                <td width="30"> ${this.getSeverityBubble(ticket)} </td>
                <td width="50">
                  <!--<m-icon name="problem"></m-icon>-->
                  ${this.getTicketId(ticket)}
                </td>
                ${displayCustomerColumn 
                  ? html`
                      <td class="nowrap" width="200">
                        <m-icon name="${this.getCustomerIcon(ticket)}"></m-icon>
                        ${this.getCustomerName(ticket)}
                      </td>
                    `
                  : ''}
                <td>
                  <m-icon name="text_snippet"></m-icon>
                  ${ticket.Subject}
                </td>
                <td width="200" class="nowrap">${this.getTicketStatus(ticket)}</td>
              </tr>
            `)}
          `)}
        </tbody>
      </table>
    `;
  }

  render() {
    return html`
      ${this.renderToolbar()}
      ${this.renderTickets()}
    `;
  }
}

customElements.define('tickets-table', TicketsTable);
